@if(loaded){
<div class="h-screen overflow-hidden">
  <div class="relative flex w-full h-full" *ngIf="true">
    <div>
      <app-navbar></app-navbar>
    </div>
    <div class="relative w-full h-full overflow-y-auto scrollbar bg-slate-100">
      <div class="relative w-full h-full">
        <app-header></app-header>
        <ng-container>
          <router-outlet></router-outlet>
        </ng-container>
      </div>
    </div>
  </div>
</div>
}@else{
<div class="h-screen w-screen flex flex-col justify-center items-center space-y-6">
  <img src="assets/images/logo1.png" class="h-20">
  <app-loader></app-loader>
</div>
}