import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductApiService } from '../../services/api/product-api.service';
import { faChevronLeft, faCopy } from '@fortawesome/free-solid-svg-icons';
import { ModalService } from '../../../../core/services/modal/modal.service';
import { Location } from '@angular/common';
import { environment } from '../../../../../environments/environment';

interface ProductSet {
  set_sku: string;
  name: string;
  active: boolean;
  products: ProductInfo[]
  total_price: number;
  total_srp: number;
  current_total_srp: number;
  price_change: boolean;
  mark_price_change: boolean;


}
interface ProductInfo {
  sku: string;
  name: string;
  qty: number;
  cost: number;
  cost_percent: number;
  srp_price: number;
  price: number;
  current_cost: number;
  current_srp_price: number;
  current_cost_percent: number;
}


@Component({
  selector: 'app-product-set-detial',
  templateUrl: './product-set-detial.component.html',
  styleUrl: './product-set-detial.component.css'
})
export class ProductSetDetialComponent implements OnInit {
  faCopy = faCopy
  faChevronLeft = faChevronLeft

  setSku = ''

  webConfig = environment.webConfig;
  costPriceIndex = this.webConfig.costPriceIndex
  srpPriceIndex = this.webConfig.srpPriceIndex
  percent = this.webConfig.percentSetList;

  productSet: Array<ProductSet> = [];
  currentProductSet: any = {}
  loading = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private productApiService: ProductApiService,
    private modalService: ModalService,
    private location: Location
  ) {

  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.setSku = params['set_sku'];
      this.loadData()
    });
    console.log(this.webConfig)
  }

  loadData() {
    this.loading = true;
    this.productApiService.getSameProductSet(this.setSku).then((response: any) => {
      console.log(response)
      if (response.success) {
        const productSet = []
        for (const set of response.data) {
          productSet.push(this.formatDataProductDetial(set))
        }
        this.productSet = productSet
        this.currentProductSet = this.productSet.find(item => item.set_sku === this.setSku)
      } else {
        this.router.navigate(['/product/product-set'], {})
      }
      this.loading = false;
    })
  }

  formatDataProductDetial(productSet: any) {
    const products = []
    let current_total_srp = 0
    let total_srp = 0

    for (const product of productSet.products) {
      const cost_percent = product.cost + ((this.percent / 100) * product.cost)
      const current_cost = productSet.product_info.find((item: any) => item.sku === product.sku)?.prices[this.costPriceIndex];
      const current_srp_price = productSet.product_info.find((item: any) => item.sku === product.sku)?.prices[this.srpPriceIndex]
      const current_cost_percent = current_cost + ((this.percent / 100) * current_cost)

      total_srp += (product.srp_price * product.qty)
      current_total_srp += (current_srp_price * product.qty)

      products.push({
        ...product,
        name: productSet.product_info.find((item: any) => item.sku === product.sku)?.name,
        cost_percent,
        current_cost,
        current_srp_price,
        current_cost_percent
      })
    }

    return {
      ...productSet,
      products,
      total_srp,
      current_total_srp
    }
  }

  copyProduct(set_sku: string) {
    console.log(set_sku)
    this.router.navigate(['/product/product-set/create'], { queryParams: { action: 'copy', data: set_sku } })
  }

  callbackActiveToggle = (event: boolean, id: string) => {
    const setSku = id.split('-')[2]
    this.productApiService.updateProductSet(setSku, { active: event }).then((res: any) => {
      if (res.success && res.data) {
        this.modalService.openModal('success', 'บันทึกสำเร็จ', '')
      } else {
        this.modalService.openModal('fail', 'บันทึกไม่สำเร็จ', '').then(res => {
          const element: any = document.querySelector(`#${id}`)
          element.checked = !event
        })
      }
      this.loadData()
    })
  }

  back() {
    this.location.back();
  }
}
