<div id="header-content" class="sticky top-0 flex justify-between items-center h-header bg-white pl-6 pr-2 w-full z-10 shadow-md">
    <div class="flex space-x-2">
        <button class="flex justify-center items-center h-full" (click)="switchSidenav()">
            <fa-icon [icon]="faBars" class=""></fa-icon>
        </button>
    </div>
    <div class="flex h-full items-center mr-4 space-x-2 justify-end">
        <div class="inline w-4/6 sm:w-fit">
            <div class="text-xs md:text-sm font-bold whitespace-nowrap text-end truncate">
                name
            </div>
            <div class="text-right">
                <div class="text-xs md:text-sm text-gray-600 whitespace-nowrap w-full truncate">
                    Admin
                </div>
            </div>
        </div>
        <button mat-icon-button class="rounded-full bg-gray-100 h-9 w-9 flex-none" routerLink="/profile">
            <img class="rounded-full bg-gray-100 h-9 w-9"
                [src]="'https://cdn-icons-png.flaticon.com/512/9187/9187604.png'" alt="user" loading="lazy"
                onerror="this.onerror=null; this.src='assets/images/user.png'">
        </button>
    </div>
</div>