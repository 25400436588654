<div class="mt-4 space-y-4">
    <div class="card">
        <form [formGroup]="form" class="grid grid-cols-2 md:grid-cols-4 gap-4">
            <div class="w-full">
                <label class="text-sm">ช่องทางการขาย</label>
                <mat-form-field class="w-full">
                    <mat-select class="" (selectionChange)="formCreateString($event.value)"
                        [value]='toggleAll? 0: channelSelected' multiple>
                        <mat-option class="s" #allSelected (click)="toggleAllSelection()"
                            [value]="0">ทั้งหมด</mat-option>
                        @for (channel of channels; track $index) {
                        <mat-option class="s" [disabled]="allSelected.selected" [value]="channel.shop_ref">
                            {{channel.platform_name}}
                        </mat-option>
                        }
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="w-full">
                <label class="text-sm">สินค้า</label>
                <div class="flex gap-2 w-full relative">
                    <input #product type="text" class="input" placeholder="SKU, ชื่อสินค้า" [(ngModel)]="keyword"
                        [ngModelOptions]="{standalone: true}" (ngModelChange)="productSearch($event)">
                    <ul class="absolute z-50 w-96 !rounded-lg bg-white mat-elevation-z8 max-h-72 overflow-y-auto top-11 py-2"
                        *ngIf="showProductFilter">
                        <li *ngFor="let product of productsFilter"
                            class="min-h-[48px] whitespace-nowrap hover:bg-secondary-100 hover:cursor-pointer flex items-center p-2 text-sm"
                            (click)="selectProduct(product)">
                            <img [src]="'https://storage.remaxthailand.co.th/img/product/' + product.sku + '/1.jpg'"
                                alt="" class="flex-none h-11 w-11 mr-2" onerror="this.onerror=null; this.src='assets/images/warranty.png'">
                            <div class="w-full flex flex-col truncate">
                                <span class="pr-2 text-blue-500">{{ product.sku }} :</span>
                                <span class="w-full truncate whitespace-nowrap">{{ product.name }}</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="">
                <label class="text-sm">วันที่</label>
                <mat-form-field class="w-full">
                    <button class="input !p-2" (click)="picker.open()">
                        <div class="text-nowrap gap-2">
                            <span class="whitespace-nowrap mr-2">{{onDateSelected(selectedStartDate)}} ถึง
                                {{onDateSelected(selectedEndDate)}}</span>
                            <fa-icon [icon]="faCalendar" class="text-gray-400"></fa-icon>
                        </div>
                    </button>
                    <mat-date-range-input class="!hidden" id="datepicker" [rangePicker]="picker" [max]="maxDate">
                        <input matStartDate [(ngModel)]="selectedStartDate" [max]="maxDate"
                            [ngModelOptions]="{standalone: true}">
                        <input matEndDate [(ngModel)]="selectedEndDate" [max]="maxDate"
                            [ngModelOptions]="{standalone: true}" (dateChange)="onDateRangeSelected()">
                    </mat-date-range-input>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
            </div>
            <button class="btn-sm btn-primary w-fit self-end" (click)="submitForm()">ค้นหา</button>
        </form>
        <div class="mt-4 w-full bg-gray-100 rounded-lg p-4 text-sm">
            <div class="flex gap-4">
                <div class="whitespace-nowrap text-gray-500">ช่องทางการขาย : </div>
                @if(this.channelSelected?.length>0){
                <div class="w-full flex flex-row gap-1 flex-wrap">
                    @for (item of displaychannel(); track $index) {
                    <div class="label label-secondary w-fit !text-xs text-nowrap">
                        {{item.platform_name}}
                        <span class="cursor-pointer ml-3" (click)="removeChannelSelected(item)">&#x2715;</span>
                    </div>
                    }
                </div>
                }@else {
                ทั้งหมด
                }
            </div>
            <div class="flex gap-4 mt-2">
                <div class="whitespace-nowrap text-gray-500">สินค้าที่เลือก : </div>
                @if(this.productSelected.length>0){
                <div class="w-full flex flex-row gap-1 flex-wrap">
                    @for (item of productSelected; track $index) {
                    <div class="label label-primary w-fit !text-xs text-nowrap">
                        {{item.name}}
                        <span class="cursor-pointer ml-3" (click)="removeProductSelected(item)">&#x2715;</span>
                    </div>
                    }
                </div>
                }@else {
                ทั้งหมด
                }
            </div>
        </div>
    </div>

    @if(loading){
    <div class="card">
        <div class="flex flex-col items-center py-6 space-y-3">
            <app-loader></app-loader>
            <div class="font-medium">Loading</div>
        </div>
    </div>
    }
    @if (!loading && tableDatasets.length === 0) {
    <div class="card">
        <div class="flex flex-col items-center py-6 space-y-3">
            <div class="h-32">
                <img src="assets/images/web_20.png" alt="" class="h-full">
            </div>
            <div class="font-medium">Data Not Found</div>
        </div>
    </div>
    }
    @else if (!loading && tableDatasets.length > 0){
    <div class="grid grid-cols-1">
        <div class="card">
            <div class="w-full">
                <div class="flex space-x-2 items-center justify-between">
                    <div class="font-medium">
                        ยอดขายสินค้า
                    </div>
                    <div class="flex space-x-2 items-center">
                        <div class="text-sm">การแสดงผล : </div>
                        <mat-form-field class="w-fit !h-9 !text-sm">
                            <mat-select class="!h-9" [value]="displayData"
                                (selectionChange)="displayDataOnchange($event)">
                                <mat-option class="!text-sm" value="total_amount">มูลค่า</mat-option>
                                <mat-option class="!text-sm" value="total_qty">จำนวนชิ้น</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="mt-4">
                <div class="div-table">
                    <table>
                        <thead>
                            <tr class="thead-tr">
                                <th class="text-left">
                                    สินค้า
                                </th>
                                @for (item of channelFiltered; track $index) {
                                <th class="text-right">
                                    <button class="w-full text-right flex flex-row space-x-1 items-center justify-end"
                                        (click)="sortData(item.shop_ref)">
                                        <div class="">{{item.platform_name}}</div>
                                        <div class="gap-0">
                                            @if (sortSelected===item.shop_ref && sortBy) {
                                            <fa-icon *ngIf="sortBy==='min'" [icon]="faCaretUp"
                                                class="text-primary-500"></fa-icon>
                                            <fa-icon *ngIf="sortBy==='max'" [icon]="faCaretDown"
                                                class="text-primary-500"></fa-icon>
                                            }@else {
                                            <fa-icon [icon]="faSort" class="text-gray-400"></fa-icon>
                                            }
                                        </div>
                                    </button>
                                </th>
                                }
                                <th class="text-right">
                                    <button class="w-full text-right flex flex-row space-x-1 items-center justify-end"
                                        (click)="sortData('')">
                                        <div class="">รวม</div>
                                        <div class="gap-0">
                                            @if (sortSelected==='' && sortBy) {
                                            <fa-icon *ngIf="sortBy==='min'" [icon]="faCaretUp"
                                                class="text-primary-500"></fa-icon>
                                            <fa-icon *ngIf="sortBy==='max'" [icon]="faCaretDown"
                                                class="text-primary-500"></fa-icon>
                                            }@else {
                                            <fa-icon [icon]="faSort" class="text-gray-400"></fa-icon>
                                            }
                                        </div>
                                    </button>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            @for (row of tableDatasets; track $index) {
                            <tr class="tbody-tr cursor-pointer" (click)="selectedRowOnChange(row)">
                                <td>
                                    <div class="flex items-center gap-2">
                                        @if(row.sku){
                                        <img [src]="'https://storage.remaxthailand.co.th/img/product/' + row.sku + '/1.jpg'"
                                            alt="" class="w-8 h-8" onerror="this.onerror=null; this.src='assets/images/warranty.png'">
                                        }
                                        <div class="whitespace-nowrap">{{row.name}}</div>
                                    </div>
                                </td>
                                @for (item of row.channels; track $index) {
                                <td class="text-right" [ngClass]="{'text-blue-500 font-semibold': $index===row.channels.length-1}">
                                    @if (displayData==='total_qty') {
                                    {{item.total_qty | number}}
                                    }@else {
                                    {{item.total_amount | number}}
                                    }
                                </td>
                                }
                            </tr>
                            <tr>
                                <td colspan="100"
                                    class="transition-all ease-in-out duration-500 relative overflow-hidden"
                                    [ngClass]="{ 'max-h-96 opacity-100': selectedRow === row.sku, 'max-h-0 opacity-0 hidden': selectedRow !== row.sku }">
                                    <div class="px-4 py-8">
                                        @if(chartLoaded && performanceDatasets.length > 0){
                                        <div class="text-center font-medium py-2">ยอดขาย 30 วันย้อนหลัง</div>
                                        <div class="h-48">
                                            <app-line-chart [chartId]="'chart-performance-'+row.sku"
                                                [legendPosition]="legendPosition"
                                                [datasets]="performanceDatasets"></app-line-chart>
                                        </div>
                                        }@else if (chartLoaded && performanceDatasets.length === 0) {
                                        <div
                                            class="h-48 w-full flex items-center justify-center bg-gray-100 rounded-lg">
                                            <div class="">ไม่พบข้อมูล</div>
                                        </div>
                                        }@else {
                                        <div
                                            class="h-48 w-full flex items-center justify-center bg-gray-100 rounded-lg">
                                            <app-loader></app-loader>
                                        </div>
                                        }
                                    </div>
                                </td>
                            </tr>
                            }
                        </tbody>
                    </table>
                </div>
                <app-pagination *ngIf="countDataSource" [callBackPageChange]="callBackPageChange" [pageSize]="pageSize"
                    [pageIndex]="pageIndex" [length]="countDataSource"></app-pagination>
            </div>
        </div>
    </div>
    }
</div>