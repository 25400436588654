import { Injectable } from '@angular/core';
import { ApiService } from '../../../../core/services/api/api.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PriceListApiService {

  endpoint = environment.endpoint;

  constructor(
    private http: HttpClient,
    private apiService: ApiService
  ) { }

  getAllPriceList(filters: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/price-list/all`, { headers, params: filters }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  searchPriceList(filters: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/price-list/search`, { headers, params: filters }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getAllPriceCampaign() {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/price-campaign/all`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  updatePriceCampaign(id: string, body: { id: string, price: number }) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.patch(`${this.endpoint}/price-list/update-price/${id}`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  updatePriceList(id: string, body: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.patch(`${this.endpoint}/price-list/update/${id}`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }


}
