import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    // private authService: AuthService
  ) { }


  getAPIHeader(): any {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6bnVsbCwidXNlcl9pZCI6ImdlbmVyYXRlX2lkIiwidHlwZSI6Im5vcm1hbCIsImlhdCI6MTY4MjQ4MDY2NywiZXhwIjozMzI0MDA4MDY2N30.UOliF3IGA_Ruaudjja6GACWIm6NYuXFxR5QGSsur99A`
    }
    return headers
  }

  parseFilters(filters: any) {
    if (filters && Object.keys(filters).length > 0) {
      const filtersItem = []
      for (const key of Object.keys(filters)) {
        filtersItem.push(`${key}=${filters[key]}`)
      }
      return filtersItem.join("&");
    } else {
      return ''
    }
  }

}
