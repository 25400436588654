<div class="content space-y-4">

    <div class="flex justify-between items-center">
        <div class="flex font-medium text-primary-400 gap-2">
            <div class="">
                <img src="assets/svg/tags.svg" alt="" class="w-5">
            </div>
            <div class="">Price List</div>
        </div>
        <div class="flex gap-2">
            <button class="btn-sm btn-primary w-fit self-end content-center" disabled>ตรวสอบราคา</button>
        </div>
    </div>

    <div class="card">
        <form [formGroup]="form" class="grid grid-cols-2 md:grid-cols-4 gap-4">
            <div class="w-full">
                <label class="text-sm">สินค้า</label>
                <input class="input" type="text" placeholder="กรอกชื่อ/รหัสสินค้า" formControlName="keyword">
            </div>
            <div class="">
                <label class="text-sm">การใช้งาน</label>
                <mat-form-field class="w-full">
                    <mat-select class="" placeholder="เลือกการใช้งาน" formControlName="visible">
                        <mat-option value="">ทั้งหมด</mat-option>
                        <mat-option value="true">เปิดใช้งาน</mat-option>
                        <mat-option value="false">ปิดใช้งาน</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <button class="btn-sm btn-primary w-fit self-end" (click)="submitForm()">ค้นหา</button>
        </form>
    </div>


    @if(loading){
    <div class="card">
        <div class="flex flex-col items-center py-6 space-y-3">
            <app-loader></app-loader>
            <div class="font-medium">Loading</div>
        </div>
    </div>
    }
    @if (!loading && dataSource.length === 0) {
    <div class="card">
        <div class="flex flex-col items-center py-6 space-y-3">
            <div class="h-32">
                <img src="assets/images/web_20.png" alt="" class="h-full">
            </div>
            <div class="font-medium">Data Not Found</div>
        </div>
    </div>
    }
    @else if (!loading && dataSource.length > 0){
    <div class="card">
        <div>
            <div class="div-table">
                <table>
                    <thead class="">
                        <tr class="thead-tr">
                            <th class="text-center sticky left-0 w-[400px] truncate bg-inherit">
                                รายการ
                            </th>
                            <th class="text-left bg-[#fffae9]">
                                วันที่
                            </th>
                            <th class="text-right bg-amber-100">
                                Retail
                            </th>
                            <th class="text-right bg-amber-100">
                                Online
                            </th>
                            <th class="text-right bg-[#fff700]">
                                ทุน (ช่อง5)
                            </th>
                            <th class="text-right bg-[#ff7d00]">
                                ทุน (+30%)
                            </th>
                            @for (campaign of priceCampaign; track $index) {
                            <th class="text-right">
                                {{campaign.name}}
                                <fa-icon [icon]="faQuestionCircle" class="text-gray-500" [matTooltip]="campaign.note"
                                    matTooltipClass="text-sm"></fa-icon>
                            </th>
                            }
                            <!-- <th class="text-right bg-pink-200">
                                MEGA FS Price
                            </th>
                            <th class="text-right bg-pink-200">
                                MEGA Campaign price
                            </th>
                            <th class="text-right bg-orange-200">
                                Double FS Price
                            </th>
                            <th class="text-right bg-orange-200">
                                Double Campaign price
                            </th>
                            <th class="text-right bg-emerald-200">
                                Level A FS Price
                            </th>
                            <th class="text-right bg-emerald-200">
                                Level A Campaign price
                            </th>
                            <th class="text-right bg-blue-300">
                                FS Price
                            </th> -->
                            <th class="text-left">
                                Note
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        @for (product of dataSource; track $index) {
                        <tr class="tbody-tr">
                            <td class="sticky left-0 w-[400px] truncate bg-inherit cursor-pointer !border-b !border-t"
                                (click)="selectedRowOnChange(product)">
                                <div class="w-[400px] flex items-center">
                                    <img [src]="'https://storage.remaxthailand.co.th/img/product/' + product.sku + '/1.jpg'"
                                        alt="" class="flex-none w-8 h-8 mr-2"
                                        onerror="this.onerror=null; this.src='assets/images/warranty.png'">
                                    <div class="w-full flex flex-col truncate">
                                        <span class="w-full truncate whitespace-nowrap">{{product.name}}</span>
                                        <span class="text-xs pr-2 text-blue-500">{{product.sku}}</span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="whitespace-nowrap">{{dateFormat(product.created.date)}}</div>
                            </td>
                            <td class="text-right">
                                <div class="">{{product.retail_price}}</div>
                            </td>
                            <td class="text-right">
                                <div class="">{{product.online_price}}</div>
                            </td>
                            <td class="text-right">
                                <div class="">{{product.cost}}</div>
                            </td>
                            <td class="text-right">
                                <div class="">{{product.cost*1.3}}</div>
                            </td>
                            @for (campaign of priceCampaign; track $index) {
                            <td class="text-right">
                                <button *ngIf="!isEditing[product.id + '' + campaign.id]"
                                    class="text-right font-semibold !w-16"
                                    [ngClass]="{'text-black': getProductPrice(product.price_campaign, campaign.id) > 0, 'text-red-500': getProductPrice(product.price_campaign, campaign.id) == 0 || !getProductPrice(product.price_campaign, campaign.id)}"
                                    (click)="toggleEditPrice(product.id, campaign.id)">
                                    {{ getProductPrice(product.price_campaign, campaign.id) | number }}
                                </button>

                                <input *ngIf="isEditing[product.id + '' + campaign.id]" type="number" id="price-add"
                                    class="input !w-16 text-right" autocomplete="off" [(ngModel)]="price"
                                    [placeholder]="getProductPrice(product.price_campaign, campaign.id) | number"
                                    (focusout)="toggleEditPrice(product.id, campaign.id)"
                                    (keyup.enter)="updatePrice(product.id, campaign.id)" />
                            </td>
                            }
                            <td class="w-fit">
                                <div class="w-max text-red-500 cursor-pointer"
                                    (click)="openNoteDialog(product.id,product.note)"
                                    [innerHTML]="setFormattingNote(product?.note)"></div>
                            </td>
                        </tr>

                        <tr class="opacity-50">
                            @if (selectedRow === product.id) {
                            <td class="sticky left-0 w-[400px] bg-white">
                                ...
                            </td>
                            <td class="">
                                @for (row of selectedPriceList; track $index) {
                                <div class="whitespace-nowrap">{{dateFormat(row.created.date)}}</div>
                                }
                            </td>
                            <td class="text-right">
                                @for (row of selectedPriceList; track $index){
                                <div class="">{{row.retail_price}}</div>
                                }
                            </td>
                            <td class="text-right">
                                @for (row of selectedPriceList; track $index){
                                <div class="">{{row.online_price}}</div>
                                }
                            </td>
                            <td class="text-right">
                                @for (row of selectedPriceList; track $index){
                                <div class="">{{row.cost}}</div>
                                }
                            </td>
                            <td class="text-right">
                                @for (row of selectedPriceList; track $index){
                                <div class="">{{row.cost*1.3}}</div>
                                }
                            </td>
                            @for (campaign of priceCampaign; track $index) {
                            <td class="text-right">
                                @for (row of selectedPriceList; track $index){
                                <div class="">{{ getProductPrice(row.price_campaign, campaign.id) | number }}</div>
                                }
                            </td>
                            }
                            <td class="text-left">
                                @for (row of selectedPriceList; track $index){
                                <div class="whitespace-nowrap text-red-500">{{row?.note || '-'}}</div>
                                }
                            </td>
                            }
                        </tr>

                        }
                    </tbody>
                </table>
            </div>
            <app-pagination [callBackPageChange]="callBackPageChange" [pageSize]="pageSize" [pageIndex]="pageIndex"
                [length]="countDataSource"></app-pagination>
        </div>
    </div>
    }


    <!-- <div class="card">
        <div>
            <div class="div-table">
                <table>
                    <thead>
                        <tr>
                            <th rowspan="2" class="border-r" style="background-color: #f7d7d7;">รายการ</th>
                            <th rowspan="2" class="border-r" style="background-color: #fffae9;">Date</th>
                            <th rowspan="2" class="border-r" style="background-color: #fcf1cd;">Retail</th>
                            <th rowspan="2" class="border-r" style="background-color: #fcf1cd;">Online</th>
                            <th rowspan="2" class="border-r" style="background-color: #fff700;">5</th>
                            <th rowspan="2" class="border-r" style="background-color: #ff7d00;">+30</th>
                            <th colspan="2" class="border-r" style="background-color: #eec9d4;">MEGA
                                campaign<br>D3/BD/D6/D9/D11/D12</th>
                            <th colspan="2" class="border-r" style="background-color: #fdd59c;">Double
                                digit<br>D2/D4/D5/D7/D8<br>Shopee 3.3 / 4.4 / BDlazada</th>
                            <th colspan="2" class="border-r" style="background-color: #9bcd88;">Level
                                A<br>PAYDAY/MM/GadgetSale<br>EL Sale Lazada / FS x Beauty Lazada / Summer Lazada</th>
                            <th rowspan="2" style="background-color: #b5d4eb;">FS Price</th>
                        </tr>
                        <tr>
                            <th class="border-r" style="background-color: #eec9d4;">FS Price</th>
                            <th class="border-r" style="background-color: #eec9d4;">Campaign price</th>
                            <th class="border-r" style="background-color: #fdd59c;">FS Price</th>
                            <th class="border-r" style="background-color: #fdd59c;">Campaign price</th>
                            <th class="border-r" style="background-color: #9bcd88;">FS Price</th>
                            <th class="border-r" style="background-color: #9bcd88;">Campaign price</th>
                        </tr>
                    </thead>
                    <tbody>
                        @for (item of [0,1]; track $index) {
                        <tr class="tbody-tr">
                            <td class="border-r">
                                <div class="whitespace-nowrap">D2100761 Power Bank 15000mAh (W1501,Black) - REMAX</div>
                            </td>
                            <td class="border-r">
                                <div class="whitespace-nowrap">2024-09-01 15:30</div>
                            </td>
                            <td class="border-r text-right">
                                200
                            </td>
                            <td class="border-r text-right">
                                200
                            </td>
                            <td class="border-r text-right">
                                200
                            </td>
                            <td class="border-r text-right">
                                200
                            </td>
                            <td class="border-r text-right">
                                100
                            </td>
                            <td class="border-r text-right">
                                100
                            </td>
                            <td class="border-r text-right">
                                100
                            </td>
                            <td class="border-r text-right">
                                100
                            </td>
                            <td class="border-r text-right">
                                100
                            </td>
                            <td class="border-r text-right">
                                100
                            </td>
                            <td class="text-right">
                                100
                            </td>
                        </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    </div> -->

</div>