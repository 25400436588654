import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './core/pages/main/main.component';
import { DataAnalysisComponent } from './features/data-analysis/pages/data-analysis/data-analysis.component';
import { OrderComponent } from './features/order/pages/order/order.component';
import { ProductSetComponent } from './features/product/pages/product-set/product-set.component';
import { ProfileComponent } from './core/pages/profile/profile.component';
import { PermissionComponent } from './features/permission/pages/permission/permission.component';
import { SettingComponent } from './features/setting/pages/setting/setting.component';
import { CreateProductSetComponent } from './features/product/pages/create-product-set/create-product-set.component';
import { ProductListComponent } from './features/product/pages/product-list/product-list.component';
import { ProductSetDetialComponent } from './features/product/pages/product-set-detial/product-set-detial.component';
import { PriceListComponent } from './features/price-list/pages/price-list/price-list.component';
// import { SaleListComponent } from './features/sales/pages/sale-list/sale-list.component';
// import { SaleDetailComponent } from './features/sales/pages/sale-detail/sale-detail.component';


const routes: Routes = [
  { path: '', redirectTo: 'main', pathMatch: 'full' },
  { path: 'main', component: MainComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'data-analysis', component: DataAnalysisComponent },
  { path: 'order', component: OrderComponent },
  { path: 'product/product-set', component: ProductSetComponent },
  { path: 'product/product-set/create', component: CreateProductSetComponent },
  { path: 'product/product-set/detial', component: ProductSetDetialComponent },
  { path: 'product/product-list', component: ProductListComponent },
  { path: 'price-list', component: PriceListComponent },
  { path: 'permission', component: PermissionComponent },
  { path: 'setting', component: SettingComponent },
  // { path: 'sales/sale-list', component: SaleListComponent, data: { expectedRole: ['admin', 'manager', 'supervisor', 'sale'] }, canActivate: [AuthGuardService] },
  // { path: 'sales/sale-detail', component: SaleDetailComponent, data: { expectedRole: ['admin', 'manager', 'supervisor', 'sale'] }, canActivate: [AuthGuardService] },
  // { path: 'forbidden', component: ForbiddenComponent },
  // { path: '**', component: PageNotFoundComponent },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
