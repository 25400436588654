<div class="content space-y-4">
    <div class="flex items-center space-x-2">
        <button class="bg-primary-500 rounded-full w-10 h-10" (click)="back()">
            <fa-icon [icon]="faChevronLeft" class="text-base text-white"></fa-icon>
        </button>
        <div class="flex font-medium text-primary-400 gap-2">
            <div class="">
                <img src="assets/svg/hierarchy.svg" alt="" class="w-5">
            </div>
            <div class="">รายละเอียดเซ็ตสินค้า</div>
        </div>
    </div>
    @if(loading){
    <div class="card">
        <div class="flex flex-col items-center py-6 space-y-3">
            <app-loader></app-loader>
            <div class="font-medium">Loading</div>
        </div>
    </div>
    }@else {
    <div class="card grid grid-cols-1 gap-4">
        <div class="font-bold">รูปสินค้า</div>
        <div class=" flex justify-start space-x-2">
            @for (product of currentProductSet.products; track $index) {
            <div [matTooltip]="product.name">
                <div class="flex items-center w-fit">
                    <img [src]="'https://storage.remaxthailand.co.th/img/product/' + product.sku + '/1.jpg'" alt=""
                        class="flex-none w-32 h-32 mr-2"
                        onerror="this.onerror=null; this.src='assets/images/warranty.png'">
                </div>
                <div class="text-center">x{{product.qty}}</div>
            </div>
            }
        </div>
    </div>
    <div class="card grid grid-cols-1 gap-4">
        <div class="w-full justify-between flex space-x-2 items-center">
            <div class="font-bold">
                รายละเอียด
                <span class="text-sm font-normal text-lime-500 pl-2">( ) = ราคาปัจจุบันลดลง</span>
                <span class="text-sm font-normal text-red-500 pl-2">( ) = ราคาปัจจุบันเพิ่มขึ้น</span>
            </div>
            <button class="btn-sm btn-secondary flex items-center space-x-2" (click)="copyProduct(setSku)">
                <fa-icon class="text-sm" [icon]="faCopy"></fa-icon>
                <div>คัดลอกเซ็ตสินค้า</div>
            </button>
        </div>
        <div class="div-table">
            <table>
                <thead>
                    <tr class="thead-tr">
                        <th class="text-left">
                            SET
                        </th>
                        <th class="text-left">
                            Product
                        </th>
                        <th class="text-right">
                            ราคาขาย
                        </th>
                        <th class="text-right">
                            ราคาเซ็ต
                        </th>
                        <th class="text-right">
                            ปลีก/ชิ้น
                        </th>
                        <th class="text-right">
                            ราคาปลีก
                        </th>
                        <th class="text-right">
                            ทุน(ช่อง 5)
                        </th>
                        <th class="text-right">
                            ทุน(+{{percent}}%)
                        </th>
                        <th class="text-right">
                            ทุนรวม
                        </th>
                        <th class="text-right">
                            เปิดใช้งาน
                        </th>
                    </tr>
                </thead>
                <tbody>
                    @for (row of productSet; track $index) {
                    <tr class="tbody-tr">
                        <td class="text-left">
                            <div class="">{{row.set_sku}}</div>
                            <div class="grid gap-2">
                                @if (row.price_change) {
                                <div class="label label-warning whitespace-nowrap !text-xs w-fit">ราคาปรับ</div>
                                }
                            </div>
                        </td>
                        <td class="text-left">
                            <div class="w-[250px] lg:w-full">
                                @for (product of row.products; track $index) {
                                <div class="whitespace-nowrap w-full truncate">
                                    x{{product.qty}} {{product.sku}} -
                                    {{product.name}}
                                </div>
                                }
                            </div>
                        </td>
                        <td class="text-right">
                            @for (product of row.products; track $index) {
                            <div class="font-semibold"
                                [ngClass]="{'text-red-500' : product.price < (product.current_cost_percent * product.qty)}">
                                {{product.price | number}}
                            </div>
                            }
                        </td>
                        <td class="text-right text-primary-500 font-semibold">
                            {{row.total_price | number}}
                        </td>
                        <td class="text-right ">
                            @for (product of row.products; track $index) {
                            <div class="flex space-x-1 items-center justify-end">
                                <div>{{product.srp_price | number}}</div>
                                @if (product.srp_price !== product.current_srp_price) {
                                <div
                                    [ngClass]="{'text-lime-500': (product.current_srp_price < product.srp_price), 'text-red-500': (product.current_srp_price > product.srp_price)}">
                                    ({{product.current_srp_price | number}})</div>
                                }
                            </div>
                            }
                        </td>
                        <td class="text-right">
                            <div class="">
                                <div>{{row.total_srp | number}}</div>
                                @if (row.total_srp !== row.current_total_srp) {
                                <div class=""
                                    [ngClass]="{'text-lime-500': (row.current_total_srp < row.total_srp), 'text-red-500': (row.current_total_srp > row.total_srp)}">
                                    ({{row.current_total_srp | number}})</div>
                                }
                            </div>
                        </td>
                        <td class="text-right">
                            @for (product of row.products; track $index) {
                            <div class="flex space-x-1 items-center justify-end">
                                <div>{{product.cost | number}}</div>
                                @if (product.cost !== product.current_cost) {
                                <div class=""
                                    [ngClass]="{'text-lime-500': (product.current_cost) < (product.cost), 'text-red-500': (product.current_cost) > (product.cost)}">
                                    ({{product.current_cost | number}})</div>
                                }
                            </div>
                            }
                        </td>
                        <td class="text-right">
                            @for (product of row.products; track $index) {
                            <div class="flex space-x-1 items-center justify-end">
                                <div>{{product.cost_percent | number}}</div>
                                @if (product.cost_percent !== product.current_cost_percent) {
                                <div class=""
                                    [ngClass]="{'text-lime-500': (product.current_cost_percent) < (product.cost_percent), 'text-red-500': (product.current_cost_percent) > (product.cost_percent)}">
                                    ({{product.current_cost_percent | number}})</div>
                                }
                            </div>
                            }
                        </td>
                        <td class="text-right">
                            @for (product of row.products; track $index) {
                            <div class="flex space-x-1 items-center justify-end">
                                <div>{{(product.cost_percent * product.qty) | number}}</div>
                                @if ((product.cost_percent * product.qty) !== (product.current_cost_percent *
                                product.qty)) {
                                <div>
                                    <div class="" [ngClass]="{'text-lime-500': (product.current_cost_percent * product.qty) < (product.cost_percent *
                                    product.qty), 'text-red-500': (product.current_cost_percent * product.qty) > (product.cost_percent *
                                    product.qty)}">
                                        ({{(product.current_cost_percent * product.qty) | number}})
                                    </div>
                                </div>
                                }
                            </div>
                            }
                        </td>
                        <td class="">
                            <div class="flex justify-end gap-2">
                                <app-toggle [id]="'detialtoggle-set-'+row.set_sku"
                                    [callbackToggle]="callbackActiveToggle" [defaultValue]="row.active"></app-toggle>
                            </div>
                        </td>
                    </tr>
                    }
                </tbody>
            </table>
        </div>
    </div>
    }
</div>