import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrl: './setting.component.css'
})
export class SettingComponent implements OnInit {

  header: any;

  menus = [
    { key: 'channel', name: 'ร้านค้า', detail: 'ตั้งค่าช่องทางการสั่งซื้อ' },
    { key: 'channel2', name: 'ร้านค้า2', detail: 'ตั้งค่าช่องทางการสั่งซื้อ' },
    { key: 'channel3', name: 'ร้านค้า3', detail: 'ตั้งค่าช่องทางการสั่งซื้อ' },
    { key: 'channel4', name: 'ร้านค้า4', detail: 'ตั้งค่าช่องทางการสั่งซื้อ' }
  ]

  menuSelected: any = this.menus[0];

  constructor(

  ) {
    this.header = document.querySelector("#header-content");
  }


  async ngOnInit(): Promise<void> {

  }

  selectedMenuOnChange(menu: any): void {
    this.menuSelected = menu;
  }

}
