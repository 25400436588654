import { Component, OnInit } from '@angular/core';
import { PlatformService } from './core/services/platform/platform.service';
import { CategoryService } from './core/services/category/category.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit {
  title = 'platform-management';
  loaded: boolean = false;

  constructor(
    private platformService: PlatformService,
    private categoryService: CategoryService
  ) {

  }

  async ngOnInit(): Promise<void> {
    await Promise.all([
      this.loadPlatform(),
      this.loadProductCategory()
    ])

    this.loaded = true;

  }

  async loadPlatform() {
    await this.platformService.getAllPlatform({}).then((res: any) => {
      if (res.success) {
        this.platformService.channels = res.data.map((e: any) => ({ platform_name: e.platform_name, shop_ref: e.shop_ref, member_id: e.member_id, color: e.color }))
      }
    })
  }

  async loadProductCategory() {
    await this.categoryService.getAllCategory().then((res: any) => {
      if (res.success) {
        this.categoryService.categories = res.data.map((e: any) => ({ id: e.id, name: e.name })).sort((a: any, b: any) => a.name - b.name)
      }
    })
  }


}
