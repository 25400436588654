import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  productSetPagination = {
    pageSize: 10,
    pageIndex: 0,
    limit: 10,
    offset: 0
  }

  productSetfilteres = {
    keyword: '',
    active: true,
    price_change: null
  }

  constructor() { }
}
