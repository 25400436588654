<div class="content space-y-4">

    <div class="flex justify-between items-center">
        <div class="flex font-medium text-primary-400 gap-2">
            <div class="">
                <img src="assets/svg/hierarchy.svg" alt="" class="w-5">
            </div>
            <div class="">รายการเซ็ตสินค้า</div>
        </div>
        <a class="btn-sm btn-primary w-fit self-end content-center" [routerLink]="['/product/product-set/create']">
            + เพิ่มสินค้าใหม่
        </a>
    </div>

    <div class="card">
        <form [formGroup]="form" class="grid grid-cols-2 md:grid-cols-4 gap-4">
            <div class="w-full">
                <label class="text-sm">สินค้า</label>
                <input class="input" type="text" placeholder="กรอกชื่อ/รหัสเซ็ต/รหัสสินค้า" formControlName="keyword">
            </div>
            <div class="">
                <label class="text-sm">การใช้งาน</label>
                <mat-form-field class="w-full">
                    <mat-select class="" placeholder="select option" formControlName="active">
                        <mat-option>ทั้งหมด</mat-option>
                        <mat-option [value]="true">เปิดใช้งาน</mat-option>
                        <mat-option [value]="false">ยกเลิกใช้งาน</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="">
                <label class="text-sm">สถานะ</label>
                <mat-form-field class="w-full">
                    <mat-select class="" placeholder="select option" formControlName="price_change">
                        <mat-option>ทั้งหมด</mat-option>
                        <mat-option [value]="true">ราคาปรับ</mat-option>
                        <mat-option [value]="false">ราคาปกติ</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <button class="btn-sm btn-primary w-fit self-end" (click)="submitForm()">ค้นหา</button>
        </form>
    </div>

    @if(loading){
    <div class="card">
        <div class="flex flex-col items-center py-6 space-y-3">
            <app-loader></app-loader>
            <div class="font-medium">Loading</div>
        </div>
    </div>
    }
    @if (!loading && datasets.length === 0) {
    <div class="card">
        <div class="flex flex-col items-center py-6 space-y-3">
            <div class="h-32">
                <img src="assets/images/web_20.png" alt="" class="h-full">
            </div>
            <div class="font-medium">Data Not Found</div>
        </div>
    </div>
    }
    @else if (!loading && datasets.length > 0){
    <div class="card">
        <div>
            <div class="div-table">
                <table>
                    <thead>
                        <tr class="thead-tr">
                            <th class="text-left">
                                SET
                            </th>
                            <th class="text-left">
                                Product
                            </th>
                            <th class="text-right">
                                ราคาขาย
                            </th>
                            <th class="text-right">
                                ราคาเซ็ต
                            </th>
                            <th class="text-right">
                                ปลีก/ชิ้น
                            </th>
                            <th class="text-right">
                                ราคาปลีก
                            </th>
                            <th class="text-right">
                                ทุน(ช่อง 5)
                            </th>
                            <th class="text-right">
                                ทุน(+{{webConfig.percentSetList}}%)
                            </th>
                            <th class="text-right">
                                ทุนรวม
                            </th>
                            <th class="text-right">
                                เปิดใช้งาน
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        @for (row of datasets; track $index) {
                        <tr class="tbody-tr" [ngClass]="{'!bg-orange-100':productSetDetial?.set_sku === row.set_sku}">
                            <td class="text-left">
                                <div class="hover:text-primary-500 hover:underline hover:cursor-pointer"
                                    (click)="showPriceChangeDetail(row.set_sku)">{{row.set_sku}}</div>
                                <div class="grid gap-2">
                                    @if (row.price_change) {
                                    <div class="label label-warning whitespace-nowrap !text-xs w-fit">ราคาปรับ</div>
                                    }
                                </div>
                            </td>
                            <td class="text-left">
                                <div class="w-[300px] lg:w-full">
                                    @for (product of row.products; track $index) {
                                    <div class="whitespace-nowrap w-full truncate">
                                        x{{product.qty}} {{product.sku}} -
                                        {{product.name}}
                                    </div>
                                    }
                                </div>
                            </td>
                            <td class="text-right">
                                @for (product of row.products; track $index) {
                                <div class="font-semibold"
                                    [ngClass]="{'text-red-500' : product.price<(product.current_cost_percent * product.qty)}">
                                    {{product.price | number}}
                                </div>
                                }
                            </td>
                            <td class="text-right text-primary-500 font-semibold">
                                {{row.total_price | number}}
                            </td>
                            <td class="text-right ">
                                @for (product of row.products; track $index) {
                                <div>{{product.srp_price | number}}</div>
                                }
                            </td>
                            <td class="text-right">
                                {{row.total_srp | number}}
                            </td>
                            <td class="text-right">
                                @for (product of row.products; track $index) {
                                <div>{{product.cost | number}}</div>
                                }
                            </td>
                            <td class="text-right">
                                @for (product of row.products; track $index) {
                                <div>{{product.cost_percent | number}}</div>
                                }
                            </td>
                            <td class="text-right text-red-500">
                                @for (product of row.products; track $index) {
                                <div>{{(product.cost_percent * product.qty ) | number}}</div>
                                }
                            </td>
                            <td class="text-right">
                                <div class="flex justify-end gap-2">
                                    <app-toggle [id]="'toggle-set-'+row.set_sku" [callbackToggle]="callbackActiveToggle"
                                        [defaultValue]="row.active"></app-toggle>
                                </div>
                            </td>
                        </tr>
                        }
                    </tbody>
                </table>
            </div>
            <app-pagination [callBackPageChange]="callBackPageChange" [pageSize]="pageSize" [pageIndex]="pageIndex"
                [length]="countDataSource"></app-pagination>
        </div>
    </div>
    }
</div>