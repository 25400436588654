import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { faChevronLeft, faPlusCircle, faXmark } from '@fortawesome/free-solid-svg-icons';
import { ProductApiService } from '../../services/api/product-api.service';
import { ModalService } from '../../../../core/services/modal/modal.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { Location } from '@angular/common';

interface ProductForm {
  sku: string;
  name: string;
  qty: number;
  srp_price: number;
  price: number;
  cost: number;
  cost_added: number;
}

@Component({
  selector: 'app-create-product-set',
  templateUrl: './create-product-set.component.html',
  styleUrls: ['./create-product-set.component.css']
})
export class CreateProductSetComponent implements OnInit {

  faPlusCircle = faPlusCircle;
  faXmark = faXmark;
  faChevronLeft = faChevronLeft

  webConfig = environment.webConfig;
  costPriceIndex = this.webConfig.costPriceIndex
  srpPriceIndex = this.webConfig.srpPriceIndex
  percent = this.webConfig.percentSetList;

  form: FormGroup;

  keyword = '';
  productsFilter: any[] = [];
  showProductFilter = false;

  constructor(
    private fb: FormBuilder,
    private productApiService: ProductApiService,
    private modalService: ModalService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location
  ) {
    this.form = this.fb.group({
      name: ['', [Validators.required]],
      products: this.fb.array([], [Validators.required])
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const action = params['action'];
      const data = params['data'];
      console.log(action, data)
      if (action === 'copy') {
        this.setDefaultCopyProduct(data)
      }
    });
  }

  async setDefaultCopyProduct(setSku: string) {
    const productSetResponse: any = await this.productApiService.getAllProductSet({ set_sku: setSku })
    console.log(productSetResponse)
    if (!productSetResponse.success && productSetResponse?.data?.length <= 0) {
      return
    }

    const productSetDetials = productSetResponse?.data[0]
    this.form.get('name')?.setValue(`${productSetDetials?.name} (COPY)`)
    const productDetials = productSetDetials?.product_info || []
    const products = productSetDetials?.products || []
    for (const product of productDetials) {
      if (product.prices) {
        const productMatch = products.find((item: any) => item.sku === product.sku);
        this.selectProduct(product, productMatch.qty)
      } else {
        console.log(product)
        this.modalService.openModal('fail', 'ไม่พบข้อมูลสินค้าบางรายการ', 'ติดเจ้าหน้าที่เพื่ออัพเดตข้อมูลสินค้าก่อน')
      }
    }
  }

  // Get products form array
  get products(): FormArray {
    return this.form.get('products') as FormArray;
  }

  // Search for products
  async productSearch() {
    if (this.keyword.length > 2) {
      const uniqueSku = this.products.controls.map(e => e.value.sku);
      this.productsFilter = (await this.searchProduct(this.keyword)).filter((e: any) => !uniqueSku.includes(e.sku));
      this.showProductFilter = true;
    } else {
      this.showProductFilter = false;
    }
  }

  clearSearch() {
    this.keyword = '';
    this.showProductFilter = false;
    setTimeout(() => {
      document.getElementById('product')?.focus();
    }, 50);
  }

  // Search product through API
  async searchProduct(keyword: string) {
    return await this.productApiService.searchProduct({ keyword, limit: 20 }).then((res: any) => {
      return res.success ? res.data : [];
    });
  }

  // Add a selected product to the form
  selectProduct(product: any, defaultQty: number) {
    if (!product.prices) {
      this.modalService.openModal('fail', 'ไม่พบราคาสินค้า', 'กรุณาอัปเดตราคาสินค้าก่อน');
      return;
    }

    const srpPrice = product.prices[this.srpPriceIndex];
    const cost = product.prices[this.costPriceIndex];
    const costAdded = this.addPercentage(cost, this.percent);

    // Add the new product to the form array
    this.products.push(this.fb.group({
      sku: [product.sku],
      name: [product.name],
      qty: [defaultQty, [Validators.required, Validators.min(1)]],
      srp_price: [srpPrice],
      price: [0, [Validators.required, Validators.min(0)]],
      cost: [cost],
      cost_added: [costAdded]
    }));

    // Clear product search list
    this.productsFilter = this.productsFilter.filter((e: any) => e.sku !== product.sku);
    if (this.productsFilter.length === 0) {
      this.showProductFilter = false;
    }
  }

  // Remove a product from the form array
  removeProduct(index: number) {
    this.products.removeAt(index);
  }

  // Add percentage to cost
  addPercentage(value: number, percent: number): number {
    return value + (value * (percent / 100));
  }

  // Calculate total quantity
  summaryItem() {
    return parseFloat(this.products.controls.reduce((sum, control) => sum + control.value.qty, 0).toFixed(2));
  }

  // Calculate total SRP
  summarySRP() {
    return parseFloat(this.products.controls.reduce((sum, control) => sum + (control.value.srp_price * control.value.qty), 0).toFixed(2));
  }

  // Calculate total cost added
  summaryCostAdded() {
    return parseFloat(this.products.controls.reduce((sum, control) => sum + (control.value.cost_added * control.value.qty), 0).toFixed(2));
  }

  // Calculate total selling price
  summaryPrice() {
    return parseFloat(this.products.controls.reduce((sum, control) => sum + control.value.price, 0).toFixed(2));
  }

  // Submit the form
  submit() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    // Handle form submission
    console.log('Form submitted:', this.form.value);
    const formValue: any = this.form.value
    const body = {
      "name": formValue.name,
      "products": formValue.products.map((item: any) => ({
        "sku": item.sku,
        "qty": item.qty,
        "price": item.price,
        "cost": item.cost,
        "srp_price": item.srp_price
      })),
      total_price: parseFloat(formValue.products.reduce((sum: number, item: any) => sum + item.price, 0).toFixed(2))
    }
    console.log(body)
    this.productApiService.createProductSet(body).then((response: any) => {
      if (response.success) {
        this.modalService.openModal('success', 'สำเร็จ', 'สร้างเซ็ตสินค้าเรียบร้อยแล้ว');
        this.router.navigate(['/product/product-set'])
      } else {
        this.modalService.openModal('fail', 'ไม่สำเร็จ', response.message);
      }
    })
  }

  // Reset the form
  cancel() {
    this.form.reset();
    while (this.products.length) {
      this.products.removeAt(0);
    }
    this.clearSearch();
    this.location.back();
  }

}
