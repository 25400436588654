import { Component, OnInit } from '@angular/core';
import { UtilService } from '../../../../core/services/util/util.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { UpdateNoteDialogComponent } from '../../components/update-note-dialog/update-note-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { PriceListApiService } from '../../services/api/price-list-api.service';

interface PriceList {
  sku: string;
  name?: string;
  cost: number;
  created: any;
  id: string;
  is_last: boolean;
  note: string;
  online_price: number;
  price_campaign: PriceCampaign[];
  retail_price: number;
  updated: any;
  visible: boolean;
}
interface PriceCampaign {
  id: string;
  price: number;
}

@Component({
  selector: 'app-price-list',
  templateUrl: './price-list.component.html',
  styleUrl: './price-list.component.css'
})
export class PriceListComponent implements OnInit {

  faQuestionCircle = faQuestionCircle;

  priceCampaign: Array<{ id: string; name: string; note: string; priority: number }> = []
  priceList: PriceList[] = []

  selectedRow: any;
  selectedPriceList: PriceList[] = []

  form: FormGroup;
  loading: boolean = true;

  dataSource: any[] = [];
  countDataSource = 0;
  pageSize = 10;
  pageIndex = 0;
  limit = 10;
  offset = 0;

  price: any;
  isEditing: any = [];

  constructor(
    private utilService: UtilService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private priceListApiService: PriceListApiService
  ) {
    this.form = this.fb.group({
      keyword: [''],
      visible: ['true']
    });
  }

  ngOnInit(): void {
    this.getPriceCampaign();
    this.getPriceListData();
  }

  getPriceCampaign() {
    this.priceListApiService.getAllPriceCampaign().then((res: any) => {
      if (res.success) {
        this.priceCampaign = res.data;
      }
    })
  }

  getPriceListData() {
    this.loading = true;
    const filters: any = { offset: this.offset, limit: this.limit, is_last: true }
    const value = this.form.getRawValue();

    if (value.keyword) {
      filters['keyword'] = value.keyword
    }
    if (value.visible) {
      filters['visible'] = value.visible
    }

    this.priceListApiService.getAllPriceList(filters).then((res: any) => {
      if (res.success) {
        this.priceList = res.data;
        this.countDataSource = res.count;
        this.setFormattingData();
      }
      this.loading = false;
    })
  }

  setFormattingNote(note: string) {
    return note.replace(/\n/g, '<br>');
  }

  searchPriceList(sku: string) {
    const filters: any = { sku: sku, is_last: false }
    this.priceListApiService.searchPriceList(filters).then((res: any) => {
      if (res.success) {
        this.selectedPriceList = res.data.sort((a: any, b: any) => b.created.date - a.created.date);
      }
    })
  }

  setFormattingData() {
    this.dataSource = this.priceList;
  }

  callBackPageChange = (data: any): void => {
    if (this.offset !== data.offset || this.limit !== data.limit) {
      this.offset = data.offset;
      this.limit = data.limit;
      this.getPriceListData()
    }

    if (data.pageIndex !== undefined) {
      this.pageIndex = data.pageIndex;
    }
    if (data.limit !== undefined) {
      this.pageSize = data.limit;
    }
  }

  selectedRowOnChange(row: any) {
    if (this.selectedRow === row.id) {
      this.selectedRow = ''
    } else {
      this.selectedRow = row.id
      this.searchPriceList(row.sku)
    }
  }

  dateFormat(date: number) {
    return this.utilService.dayjs(date).format('DD/MM/YYYY, HH:mm')
  }

  getProductPrice(productPriceCampaign: any[], campaignId: string) {
    return productPriceCampaign.find(e => e.id === campaignId)?.price || 0
  }

  toggleEditPrice(priceListId: string, campaignId: string) {
    this.price = null;
    this.isEditing[`${priceListId}${campaignId}`] = this.isEditing[`${priceListId}${campaignId}`] ? !this.isEditing[`${priceListId}${campaignId}`] : true;

    if (this.isEditing[`${priceListId}${campaignId}`]) {
      setTimeout(() => {
        document.getElementById('price-add')?.focus()
      }, 10)
    }
  }

  updatePrice(priceListId: string, campaignId: string) {
    setTimeout(() => {
      document.getElementById('price-add')?.blur()
    }, 10)

    if (this.price) {
      const body = {
        id: campaignId,
        price: this.price
      }
      this.priceListApiService.updatePriceCampaign(priceListId, body).then((response: any) => {
        console.log(response)
        if (response.success) {
          const index = this.dataSource.findIndex((item: any) => item.id === priceListId)
          if (index > -1) {
            const idx = this.dataSource[index].price_campaign.findIndex((e: any) => e.id === campaignId)
            if (idx > -1) {
              this.dataSource[index].price_campaign[idx] = body
            } else {
              this.dataSource[index].price_campaign.push(body)
            }
          }
        }
      })
    }
  }

  openNoteDialog(priceListId: string, note: string) {
    const dialogRef = this.dialog.open(UpdateNoteDialogComponent, {
      data: { note: note },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(result);
      if (result.confirm) {
        this.priceListApiService.updatePriceList(priceListId, { note: result.data.note }).then((res: any) => {
          if (res.success) {
            const index = this.dataSource.findIndex(e => e.id === priceListId)
            if (index > -1) {
              this.dataSource[index].note = result.data.note;
            }
          }
        })
      }
    });
  }

  submitForm() {
    this.getPriceListData();
  }



}
