import { Component, Input, OnInit } from '@angular/core';
import { DataAnalysisApiService } from '../../services/api/data-analysis-api.service';
import { UtilService } from '../../../../core/services/util/util.service';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { PlatformService } from '../../../../core/services/platform/platform.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { map, Observable, startWith } from 'rxjs';
import { faCaretDown, faCaretUp, faSort } from '@fortawesome/free-solid-svg-icons';

interface ChartDataset {
  name: string;
  series: Series[]
}
interface Series {
  name: string;
  value: number;
}

interface TableDataset {
  sku: string;
  name: string;
  channels: ChannelSummary[],
  summary_qty: number;
  summary_amount: number;
}
interface ChannelSummary {
  channel: string;
  total_amount: number;
  total_qty: number;
}

@Component({
  selector: 'app-data-analysis-product-overview',
  templateUrl: './data-analysis-product-overview.component.html',
  styleUrl: './data-analysis-product-overview.component.css'
})
export class DataAnalysisProductOverviewComponent implements OnInit {

  faCalendar = faCalendar;
  faCaretDown = faCaretDown;
  faCaretUp = faCaretUp;
  faSort = faSort;

  chartDatasets: ChartDataset[] = [];
  tableDatasets: TableDataset[] = []

  channels: any[] = this.platformService?.channels || [];
  channelFiltered: any[] = this.platformService?.channels || [];

  products: Array<{ sku: string; name: string }> = [];
  keyword: string = '';
  productsFilter: any = [];
  showProductFilter = false;
  productSelected: Array<{ sku: string; name: string }> = [];

  productFiltered: Array<{ sku: string; total_qty: number; total_amount: number }> = []
  productSummary: Array<{ channel: string; sku: string; total_qty: number; total_amount: number }> = []

  summaryAmount: number = 0;
  summaryQty: number = 0;

  displayData: string = 'total_qty';
  channelSelected: any;
  toggleAll: boolean = true;

  selectedStartDate: any;
  selectedEndDate: any;
  maxDate = this.utilService.dayjs().format();

  form: FormGroup;
  loading: boolean = true;

  sortSelected: string = '';
  sortBy: string = 'max';

  constructor(
    private dataAnalysisApiService: DataAnalysisApiService,
    private utilService: UtilService,
    private platformService: PlatformService,
    private fb: FormBuilder,
  ) {
    this.selectedStartDate = this.utilService.dayjs().startOf('month').format()
    this.selectedEndDate = this.utilService.dayjs().format()
    const date = `${this.utilService.dayjs(this.selectedStartDate).format('YYYY-MM-DD')},${this.utilService.dayjs(this.selectedEndDate).format('YYYY-MM-DD')}`
    this.form = this.fb.group({
      date: date,
      sku: [''],
      channel: ['']
    });

  }

  ngOnInit(): void {
    this.getDataReport();
  }

  getDataReport() {
    this.loading = true;
    const value = this.form.getRawValue()

    let filter: any = { limit: 10, sort_field: this.displayData, sort_by: this.sortBy }
    if (value.date) {
      filter['date'] = value.date
    }
    if (this.channelSelected?.length > 0) {
      filter['field_channel'] = this.channelSelected.join()
      this.channelFiltered = this.channels.filter(c => this.channelSelected.includes(c.shop_ref))
    } else {
      this.channelFiltered = this.channels
    }
    if (this.productSelected.length > 0) {
      filter['sku'] = this.productSelected.map(e => e.sku).join()
    }
    if (this.sortSelected) {
      filter['channel'] = this.sortSelected
    }

    this.productFiltered = [];
    this.productSummary = [];

    this.dataAnalysisApiService.getProductSummaryReport(filter).then(async (res: any) => {
      if (res.success) {
        this.productFiltered = res.data.products;
        this.productSummary = res.data.summary;
        const uniqueSku = [...new Set(res.data.products.map((e: any) => e.sku))];
        if (uniqueSku.length > 0) {
          await this.findAllProduct({ sku: uniqueSku.join(), filed: 'sku,name' });
        }
      }
      this.setFormatingData()
      this.calculateStatistic()
      this.loading = false;
    })
  }

  async searchProduct(keyword: string) {
    return await this.dataAnalysisApiService.searchProduct({ keyword, limit: 20 }).then((res: any) => {
      if (res.success) {
        return res.data;
      }
      return []
    })
  }

  async findAllProduct(filter: any) {
    await this.dataAnalysisApiService.getAllProduct(filter).then((res: any) => {
      if (res.success) {
        this.products = res.data;
      } else {
        this.products = []
      }
    })
  }

  setFormatingData() {
    this.chartDatasets = [];
    this.tableDatasets = []
    for (const channel of this.channelFiltered) {
      this.chartDatasets.push({
        name: channel.platform_name,
        series: this.productSummary.filter(e => e.channel === channel.shop_ref).map(e => ({
          name: this.products.find(p => p.sku === e.sku)?.name || e.sku,
          value: this.displayData === 'total_qty' ? e.total_qty : e.total_amount
        }))
      })
    }

    for (const product of this.productFiltered) {
      const channels = []
      for (const channel of this.channelFiltered) {
        const res = this.productSummary.find(e => e.channel === channel.shop_ref && e.sku === product.sku)
        channels.push({
          channel: channel.shop_ref,
          total_amount: res?.total_amount || 0,
          total_qty: res?.total_qty || 0
        })
      }
      this.tableDatasets.push({
        sku: product.sku,
        name: this.products.find(p => p.sku === product.sku)?.name || '',
        channels: channels,
        summary_qty: product.total_qty,
        summary_amount: product.total_amount
      })
    }

    this.tableDatasets.push({
      name: 'รวม',
      sku: '',
      channels: this.channelFiltered.map(e => ({
        channel: e.shop_ref,
        total_amount: this.productSummary.filter(p => p.channel === e.shop_ref).reduce((n, { total_amount }) => n + total_amount, 0),
        total_qty: this.productSummary.filter(p => p.channel === e.shop_ref).reduce((n, { total_qty }) => n + total_qty, 0)
      })),
      summary_amount: this.productFiltered.reduce((n, { total_amount }) => n + total_amount, 0),
      summary_qty: this.productFiltered.reduce((n, { total_qty }) => n + total_qty, 0)
    })
    console.log(this.tableDatasets);
  }

  calculateStatistic() {
    this.summaryAmount = this.productFiltered.reduce((n, { total_amount }) => n + total_amount, 0)
    this.summaryQty = this.productFiltered.reduce((n, { total_qty }) => n + total_qty, 0)
  }

  formCreateString(value: any) {
    this.channelSelected = value;
  }

  toggleAllSelection() {
    this.toggleAll = !this.toggleAll
    if (this.toggleAll) {
      this.channelSelected = []
    } else {
      delete this.channelSelected
    }
  }

  removeChannelSelected(channel: any) {
    this.channelSelected = this.channelSelected.filter((e: string) => e !== channel.shop_ref)
    if (this.channelSelected.length === 0) {
      this.toggleAllSelection()
    }
  }

  displaychannel() {
    return this.channels.filter(c => this.channelSelected.includes(c.shop_ref))
  }

  displayDataOnchange(event: any) {
    this.displayData = event.value
    this.getDataReport()
  }

  onDateRangeSelected() {
    if (this.selectedEndDate && this.selectedStartDate) {
      const date = `${this.utilService.dayjs(this.selectedStartDate).format('YYYY-MM-DD')},${this.utilService.dayjs(this.selectedEndDate).format('YYYY-MM-DD')}`
      this.form.get('date')?.setValue(date)
      this.form.get('date')?.updateValueAndValidity();
    }
  }

  onDateSelected(date: any) {
    return this.utilService.dayjs(date).format('DD-MM-YYYY')
  }

  async productSearch(dataSearch: any) {
    if (this.keyword.length > 2) {
      const uniquesku = this.productSelected.map(e => e.sku);
      this.productsFilter = (await this.searchProduct(this.keyword)).filter((e: any) => !uniquesku.includes(e.sku))
      this.showProductFilter = true;
    } else {
      this.showProductFilter = false;
    }
  }

  selectProduct(product: any) {
    this.productSelected.push(product)
    this.productsFilter = this.productsFilter.filter((e: any) => e.sku !== product.sku)
    if (this.productsFilter.length === 0) {
      this.showProductFilter = false;
    }
  }

  removeProductSelected(product: any) {
    this.productSelected = this.productSelected.filter(e => e.sku !== product.sku)
  }

  submitForm() {
    this.sortSelected = '';
    this.sortBy = 'max'
    this.getDataReport();
  }

  sortData(shop_ref: string) {

    if (this.sortSelected !== shop_ref) {
      this.sortSelected = shop_ref;
      this.sortBy = 'max'
    } else {
      if (!this.sortBy) this.sortBy = 'max'
      else if (this.sortBy === 'max') this.sortBy = 'min'
      else if (this.sortBy === 'min') this.sortBy = 'max'
    }

    // call api
    this.getDataReport();
    console.log(this.sortSelected, this.sortBy);
  }
  
}