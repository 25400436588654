<div class="p-10">
    <div class="pb-8">
        <h1>Buttons Size</h1>
        <div class="flex space-x-4">
            <button class="btn-sm btn-primary">btn-sm</button>
            <button class="btn btn-primary">btn</button>
            <button class="btn-lg btn-primary">btn-lg</button>
        </div>
    </div>
    <div class="pb-8">
        <h1>Button Active</h1>
        <div class="flex space-x-4">
            <button class="btn btn-primary">btn-primary</button>
            <button class="btn btn-secondary">btn-secondary</button>
            <button class="btn btn-light">btn-light</button>
            <!-- <button class="btn btn-success">btn-success</button>
            <button class="btn btn-warning">btn-warning</button> -->
            <button class="btn btn-danger">btn-danger</button>
        </div>
    </div>
    <div class="pb-8">
        <h1>Button Disabled</h1>
        <div class="flex space-x-4">
            <button class="btn btn-primary" disabled>btn-primary</button>
            <button class="btn btn-secondary" disabled>btn-secondary</button>
            <button class="btn btn-light" disabled>btn-light</button>
            <!-- <button class="btn btn-success" disabled>btn-success</button>
            <button class="btn btn-warning" disabled>btn-warning</button> -->
            <button class="btn btn-danger" disabled>btn-danger</button>
        </div>
    </div>
    <div class="pb-8">
        <h1>Label</h1>
        <div class="flex space-x-4">
            <div class="label label-primary">label-primary</div>
            <div class="label label-secondary">label-secondary</div>
            <div class="label label-light">label-light</div>
            <div class="label label-success">label-success</div>
            <div class="label label-warning">label-warning</div>
            <div class="label label-danger">label-danger</div>
        </div>
    </div>

    <div class="pb-8">
        <h1>Modal</h1>
        <div class="flex flex-wrap gap-2">
            <button class="btn btn-light" (click)="exampleModal('info')">info</button>
            <button class="btn btn-light" (click)="exampleModal('success')">success</button>
            <button class="btn btn-light" (click)="exampleModal('confirm')">Confirm</button>
            <button class="btn btn-light" (click)="exampleModal('confirm-warning')">Confirm Waning</button>
            <button class="btn btn-light" (click)="exampleModal('fail')">fail</button>
        </div>
    </div>
    <div class="pb-8">
        <h1>Select</h1>
        <mat-form-field class="w-full max-w-md">
            <mat-select class="" placeholder="select option">
                <mat-option value="option1">option 1</mat-option>
                <mat-option value="option2">option 2</mat-option>
                <mat-option value="option3">option 3</mat-option>
                <mat-option value="option4">option 4</mat-option>
                <mat-option value="option5">option 5</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="pb-8">
        <h1>Input</h1>
        <div class="grid grid-cols-3 gap-4">
            <div class="relative w-full">
                <label for="example-input">Normal</label>
                <input type="text" id="example-input" class="input" placeholder="ตัวอย่างอักษร" autocomplete="off">
            </div>
            <div class="relative w-full">
                <label for="example-input">Invalid</label>
                <input type="text" id="example-input" class="input-invalid" placeholder="ตัวอย่างอักษร"
                    autocomplete="off">
                <div class="error-message">Name is required</div>
            </div>
            <div class="col-start-1 w-full">
                <div class="flex flex-row justify-between items-center border bg-white rounded-lg">
                    <input type="text" id="keyword"
                        class="py-2.5 pl-2.5 !pr-8 placeholder:text-sm  w-full outline-none rounded-l-lg h-9 focus:ring-2 focus:ring-inset focus:ring-primary-500"
                        placeholder="Search..." autocomplete="off">
                    <button type="button"
                        class="flex-none  aspect-square bg-primary-500 text-white rounded-r-lg flex items-center justify-center h-9">
                        <fa-icon [icon]="faMagnifyingGlass" class="text-xl"></fa-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="pb-8">
        <h1>Check</h1>
        <div class="flex gap-2">
            <input type="radio" name="hs-default-radio" id="hs-default-radio">
            <label for="hs-default-radio" class="">Default radio</label>
        </div>
        <div class="flex gap-2">
            <input type="radio" name="hs-default-radio" id="hs-checked-radio" checked="">
            <label for="hs-checked-radio" class="">Checked radio</label>
        </div>
        <div class="flex gap-2">
            <input type="checkbox" name="hs-default-checkbox" id="hs-default-checkbox" [checked]="" class="" />
            <label for="hs-default-checkbox" class="">Default checkbox</label>
        </div>
        <div class="flex gap-2">
            <input type="checkbox" name="hs-checked-checkbox" id="hs-checked-checkbox" [checked]="true" class="" />
            <label for="hs-checked-checkbox" class="">Checked checkbox</label>
        </div>
        <div class="flex gap-2">
            <app-toggle [id]="'toggle-printer-active'" [callbackToggle]="callbackActiveToggle"
                [defaultValue]="true"></app-toggle>
        </div>
    </div>

    <div class="pb-8 flex w-full gap-8">
        <div class="flex-1">
            <h1>Card</h1>
            <div class="card">DATA</div>
        </div>
        <div class="flex-1">
            <h1>Loader</h1>
            <app-loader></app-loader>
        </div>

    </div>

    <div class="space-y-2">
        <h1>
            Table
        </h1>
        <div>
            <div class="div-table">
                <table>
                    <thead>
                        <tr class="thead-tr">
                            <th class="text-left">
                                table
                            </th>
                            <th class="text-left">
                                table
                            </th>
                            <th class="text-left">
                                table
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="tbody-tr">
                            <td>
                                data
                            </td>
                            <td>
                                data
                            </td>
                            <td>
                                data
                            </td>
                        </tr>
                        <tr class="tbody-tr">
                            <td>
                                data
                            </td>
                            <td>
                                data
                            </td>
                            <td>
                                data
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <app-pagination [callBackPageChange]="callBackPageChange" [pageSize]="pageSize" [pageIndex]="pageIndex"
                [length]="countDataSource"></app-pagination>
        </div>
    </div>

</div>