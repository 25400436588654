import { Injectable } from '@angular/core';
import { default as dayjs } from 'dayjs';
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/th";
import buddhistEra from "dayjs/plugin/buddhistEra";
import { faAngry, faListAlt, faUser, } from '@fortawesome/free-regular-svg-icons';
import { faCartShopping, faChartLine, faGears, faTags, faUserAstronaut } from '@fortawesome/free-solid-svg-icons';

dayjs.extend(buddhistEra);
dayjs.extend(relativeTime);
dayjs.locale("th");

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  dayjs = dayjs;
  navbarOpen = false;
  loading = false;

  menuList: any[] = [
    {
      path: '/main',
      name: 'ตัวอย่าง',
      icon: faAngry,
      visible: false,
      access: true
    },
    {
      path: '/profile',
      name: 'ข้อมูลส่วนตัว',
      icon: faUser,
      visible: false,
      access: true
    },
    {
      path: '/data-analysis',
      name: 'วิเคราะห์ข้อมูล',
      icon: faChartLine,
      visible: true,
      access: true
    },
    {
      path: '/order',
      name: 'คำสั่งซื้อ',
      icon: faListAlt,
      visible: false,
      access: true
    },
    {
      path: '/product',
      name: 'สินค้า',
      icon: faCartShopping,
      visible: true,
      access: true,
      isShowSub: true,
      submenu: [
        {
          path: "/product/product-set",
          name: 'เซ็ตสินค้า',
          description: 'เซ็ตสินค้า',
          visible: true,
          access: true
        },
        {
          path: "/product/product-list",
          name: 'สินค้า',
          description: 'สินค้า',
          visible: true,
          access: true
        },
        {
          path: "/product/product-set/create",
          name: 'สร้างเซ็ตสินค้า',
          description: 'สร้างเซ็ตสินค้า',
          visible: false,
          access: true
        },
        {
          path: "/product/product-set/detail",
          name: 'รายละเอียดเซ็ตสินค้า',
          description: 'รายละเอียดเซ็ตสินค้า',
          visible: false,
          access: true
        },
      ],
    },
    {
      path: '/price-list',
      name: 'Price List',
      icon: faTags,
      visible: true,
      access: true
    },
    {
      path: '/permission',
      name: 'สิทธิ์การเข้าใช้งาน',
      icon: faUserAstronaut,
      visible: false,
      access: true
    },
    {
      path: '/setting',
      name: 'ตั้งค่า',
      icon: faGears,
      visible: false,
      access: true
    }
  ]

  constructor() { }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  closeToggleNevbar() {
    this.navbarOpen = false
  }

  screenLoader(value: boolean) {
    this.loading = value;
  }

}
