import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';

import { HttpClientModule } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BaseChartDirective } from 'ng2-charts';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatMomentDateModule } from "@angular/material-moment-adapter"
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { MainComponent } from './core/pages/main/main.component';
import { HeaderComponent } from './core/components/header/header.component';
import { NavbarComponent } from './core/components/navbar/navbar.component';
import { ModalComponent } from './core/components/modal/modal.component';
import { DataAnalysisComponent } from './features/data-analysis/pages/data-analysis/data-analysis.component';
import { OrderComponent } from './features/order/pages/order/order.component';
import { ProductSetComponent } from './features/product/pages/product-set/product-set.component';
import { LoaderComponent } from './core/components/loader/loader.component';
import { LineChartComponent } from './core/components/line-chart/line-chart.component';
import { PaginationComponent } from './core/components/pagination/pagination.component';
import { MonthYearPickerComponent } from './core/components/month-year-picker/month-year-picker.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GroupBarChartComponent } from './core/components/group-bar-chart/group-bar-chart.component';
import { ProfileComponent } from './core/pages/profile/profile.component';
import { SelectDateOptionComponent } from './core/components/select-date-option/select-date-option.component';
import { PermissionComponent } from './features/permission/pages/permission/permission.component';
import { DataAnalysisProductComponent } from './features/data-analysis/components/data-analysis-product/data-analysis-product.component';
import { ToggleComponent } from './core/components/toggle/toggle.component';
import { DataAnalysisProductOverviewComponent } from './features/data-analysis/components/data-analysis-product-overview/data-analysis-product-overview.component';
import { DataAnalysisSaleComponent } from './features/data-analysis/components/data-analysis-sale/data-analysis-sale.component';
import { SettingComponent } from './features/setting/pages/setting/setting.component';
import { CreateProductSetComponent } from './features/product/pages/create-product-set/create-product-set.component';
import { ProductListComponent } from './features/product/pages/product-list/product-list.component';
import { DataAnalysisProductSetComponent } from './features/data-analysis/components/data-analysis-product-set/data-analysis-product-set.component';
import { ProductSetDetialComponent } from './features/product/pages/product-set-detial/product-set-detial.component';
import { PriceListComponent } from './features/price-list/pages/price-list/price-list.component';
import { UpdateNoteDialogComponent } from './features/price-list/components/update-note-dialog/update-note-dialog.component';


@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    HeaderComponent,
    NavbarComponent,
    ModalComponent,
    DataAnalysisComponent,
    OrderComponent,
    ProductSetComponent,
    LoaderComponent,
    LineChartComponent,
    PaginationComponent,
    MonthYearPickerComponent,
    GroupBarChartComponent,
    ProfileComponent,
    SelectDateOptionComponent,
    PermissionComponent,
    DataAnalysisProductComponent,
    ToggleComponent,
    DataAnalysisProductOverviewComponent,
    DataAnalysisSaleComponent,
    SettingComponent,
    CreateProductSetComponent,
    ProductListComponent,
    DataAnalysisProductSetComponent,
    ProductSetDetialComponent,
    PriceListComponent,
    UpdateNoteDialogComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    BaseChartDirective,
    MatTabsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    ReactiveFormsModule,
    FormsModule,
    NgxChartsModule,
    MatInputModule,
    MatTooltipModule
  ],
  providers: [
    provideAnimationsAsync(),
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {

  }
}
